/**
 * Do not edit this file! 
 * You should not write CSS directly when using React Native.
 * We are using CSS resets here to support React Native for Web and Tailwind CSS.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}
